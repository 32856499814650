import { Type, Action } from "../actions/UserActions";
// import { Roles } from '../../../../shared/user';

export interface IUser {
  id: string;
  displayName: string;
  mail: string;
  jobTitle?: string;
  buisnessPhones?: string[];
  mobilePhone?: string;
  officeLocation?: string;
  perferredLanguage: string;
}

export interface IUserMap {
  [key: string]: IUser;
}

export interface IUserGroupMap {
  [key: string]: any;
}

export interface IUserState {
  user: IUser | null;
  userMap: IUserMap;
  userGroupMap: IUserGroupMap;
  usersLoaded: boolean;
  roles: string[];
}

const defaultState: IUserState = {
  user: null,
  roles: [],
  userMap: {},
  userGroupMap: {},
  usersLoaded: false,
};

export const hasRecipientRole = (roles: string[]) => {
  return roles.includes("recipient");
};

export const hasAdminRole = (roles: string[]) => {
  return roles.includes("admin");
};

export const hasSenderRole = (roles: string[]) => {
  return roles.includes("sender");
};

export const UserReducer = (
  state: IUserState = defaultState,
  action: Action
): IUserState => {
  switch (action.type) {
    case Type.SET_USER:
      return { ...state, user: action.user };
    case Type.SET_USER_ROLES:
      return { ...state, roles: action.roles ? [...action.roles] : [] };
    case Type.SET_USERS_AND_GROUPS:
      return {
        ...state,
        usersLoaded: true,
        userMap: { ...action.userMap },
        userGroupMap: { ...action.userGroupMap },
      };
    default:
      return state;
  }
};
