import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreatedMemos } from "../../services/createdMemos";
import { IMemo } from "../../Memo";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CustomInput,
  Progress,
} from "reactstrap";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { IRootState } from "../../redux/reducers";
import uuid from "uuid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner";

enum CreatedMemoOptions {
  All,
  Created,
}

const globalFilter = (filter: any, row: any, column: any): any => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id]).toUpperCase().indexOf(filter.value.toUpperCase()) > -1
    : true;
};

const showCreatedMemoOptions = [
  { value: CreatedMemoOptions.All, label: "All Created Memos" },
  { value: CreatedMemoOptions.Created, label: "Your Created Memos" },
];

export const CreatedMemos = () => {
  const [memos, setMemos] = useState<IMemo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreatedMemoOption, setShowCreatedMemoOption] =
    useState<CreatedMemoOptions>(CreatedMemoOptions.All);
  const [isShowOnlyAcknowledged, setIsShowOnlyAcknowledged] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const userObj = useSelector((state: IRootState) => state.user);
  // const userMap = useSelector((state: IRootState) => state.user.userMap);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const fetchCreatedMemos = async () => {
      setIsLoading(true);
      const fetchedMemos = await getCreatedMemos();
      if (fetchedMemos) {
        setMemos(fetchedMemos);
      }
      setIsLoading(false);
    };
    fetchCreatedMemos();
    return () => {};
  }, [dispatch]);

  const memoTable = useMemo(() => {
    const tableRows: any[] = [];
    if (!userObj.user) {
      return [];
    }

    for (const memo of memos) {
      if (
        showCreatedMemoOption === CreatedMemoOptions.Created &&
        memo.userId !== userObj.user.id
      ) {
        continue;
      }

      const numAcknowledged = memo.numAcknowledged; //Object.keys(memo.usersAcknowledged).length;
      const recipients = memo.recipients;
      if (isShowOnlyAcknowledged && numAcknowledged === memo.numRecipients) {
        continue;
      }

      // recipients.sort((a: IMemoEmailRecipient, b: IMemoEmailRecipient) => {
      //   return memo.usersAcknowledged[a.userId] ? 1 : -1;
      // });
      const row = {
        createdAt: memo.createdAt,
        classification: memo.classification,
        subject: memo.subject,
        memoId: memo.memoId,
        completion: (memo.numAcknowledged / memo.numRecipients) * 100, //Added to calculate acknowledged % of total
        numAcknowledged: `${numAcknowledged} / ${memo.numRecipients}`,
        didAllAcknowledge: numAcknowledged === memo.numRecipients,
        createdByEmail: memo.createdByEmail,
        recipients,
        // usersAcknowledged: memo.usersAcknowledged
      };
      tableRows.push(row);
    }
    return tableRows;
  }, [memos, showCreatedMemoOption, userObj, isShowOnlyAcknowledged]);

  const columns = useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "subject",
        Cell: (props: any) => <span className="number">{props.value}</span>, // Custom cell components!
        filterMethod: globalFilter,
        Filter: ({ filter, onChange }: any) => {
          return (
            <div style={{ position: "relative" }}>
              <input
                onChange={(event) => onChange(event.target.value)}
                value={filter ? filter.value : ""}
                style={{
                  width: "80%",
                  backgroundColor: "#4f677d",
                  color: "#ffffff",
                }}
              />
              <i
                style={{
                  position: "relative",
                  right: "-5px",
                  lineHeight: "30px",
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </i>
            </div>
          );
        },
      },

      {
        Header: "Class",
        accessor: "classification",
        Cell: (props: any) => (
          <div
            // className={props.value ? "text-success" : "text-danger"}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {props.value ? 
            props.value
             : '--'}
          </div>
        ),
        filterable: false,
      },

      {
        Header: (props: any) => <span>Created</span>, // Custom header components!
        accessor: "createdAt",
        filterable: false,
        show: width > 500,

        Cell: (props: any) => <span>{moment(props.value).format("LLL")}</span>,
      },
      {
        Header: "By",
        accessor: "createdByEmail",
        show: width > 500,

        filterable: false,
      },
      {
        Header: "Users Ack",
        accessor: "completion",
        Cell: (props: any) => {
          const success = props.original.didAllAcknowledge;

          return (
            <div
              // className={success ? "bg-success" : "bg-danger"}
              style={{ width: "75%", height: "100%", borderRadius: "10px" }}
            >
              {props.original.completion === 0 && (
                <Progress color="danger" value={100}>
                  {props.original.numAcknowledged}
                </Progress>
              )}
              {props.original.completion !== 0 && (
                <Progress
                  className={"bg-light"}
                  color={
                    props.original.completion === 100 ? "success" : "warning"
                  }
                  value={props.original.completion}
                >
                  {props.original.numAcknowledged}
                </Progress>
              )}
            </div>
          );
        },
        filterable: false,
      },
      // {
      //   Header: "View",
      //   accessor: "memoId",
      //   width: 100,
      //   filterable: false,
      //   Cell: (props: any) => (
      //     <Link to={`/createdMemos/${props.value}`} target="_blank">View</Link>
      //   )
      // }
    ],
    []
  );
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    // Removed maxWidth={false} prop due to console error.

    <Container style={{ textAlign: "center" }}>
      <Card className="my-3">
        <CardHeader>
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Col md>
              <h5>
                <b>Created Memos</b>
              </h5>
            </Col>

            <Col md>
              <Row style={{ alignItems: "center" }}>
                <Col md style={{ alignContent: "right" }}>
                  Show:
                </Col>
                <Col md={9} style={{ color: "black" }}>
                  <Select
                    options={showCreatedMemoOptions}
                    defaultValue={showCreatedMemoOptions[0]}
                    onChange={(option: any) => {
                      setShowCreatedMemoOption(option.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md>
              <Row style={{ alignItems: "center" }}>
                <Col className="my-2">
                  <CustomInput
                    onChange={(e: any) =>
                      setIsShowOnlyAcknowledged(e.target.checked)
                    }
                    type="switch"
                    id="showUnreadSwitch"
                    name="showUnreadSwitch"
                    label="Show only unacknowledged"
                  />
                  {/* <Input
                    type={"checkbox"}
                    onChange={(e: any) =>
                      setIsShowOnlyAcknowledged(e.target.checked)
                    }
                    style={{ marginLeft: "1rem" }}
                    defaultChecked={isShowOnlyAcknowledged}
                  /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <ReactTable
            className={"-highlight -striped"}
            loading={isLoading}
            data={memoTable}
            columns={columns}
            filterable
            style={{ maxHeight: "100%" }}
            defaultPageSize={10}
            defaultSorted={[{ id: "createdAt", desc: true }]}
            getTrProps={(state: any, rowInfo: any) => {
              const key = rowInfo ? rowInfo.original.memoId : uuid.v1();
              return {
                id: key,
                key,
              };
            }}
            // SubComponent={(row: any) => {
            //   return (
            //     <CreatedMemoDetails
            //       memoId={row.original.memoId}
            //       isShowOnlyAcknowledged={isShowOnlyAcknowledged}
            //     />
            //   );
            // }}
            getTrGroupProps={(
              selected: any,
              rowInfo: any,
              column: any,
              instance: any
            ) => {
              if (rowInfo !== undefined) {
                return {
                  onClick: (e: any) => {
                    console.log(
                      "This is the memo ID: ",
                      rowInfo.original.memoId
                    );
                    history.push(`/createdMemos/${rowInfo.original.memoId}`);
                  },
                  style: {
                    cursor: "pointer",
                  },
                };
              }
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
