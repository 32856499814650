import React, { useState, useLayoutEffect } from "react";
import { IUserMemo } from "../Memo";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  // UncontrolledPopover,
  // PopoverHeader,
  // PopoverBody
} from "reactstrap";
import { LoadingSpinner } from "./Loading/LoadingSpinner";
import moment from "moment";
// import axios from "axios";

export interface IMemoProps {
  memo: IUserMemo;
  memoUrl: string;
}

interface IProps extends IMemoProps {
  acknowledgeMemo: (memoId: string) => void;
  isLoading: boolean;
  showAcknowledge: boolean;
}

export const Memo = (props: IProps) => {
  const { memo, memoUrl, acknowledgeMemo, isLoading } = props;
  const [, setHeight] = useState<number>(window.innerHeight);
  const [pdfUrl, setPDFUrl] = useState<string | null>(null);

  useLayoutEffect(() => {
    const getPDF = async () => {
      const pdf = await fetch(memoUrl);
      const blob = await pdf.blob(); //new File(, "test.pdf");
      const url = URL.createObjectURL(blob);
      setPDFUrl(url);
    };
    getPDF();

    const resize = () => {
      // We execute the same script as before
      let vh = window.innerHeight;
      setHeight(vh);
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [memoUrl]);

  const submitAcknowledgeMemo = () => {
    acknowledgeMemo(memo.memoId);
  };

  const getAcknowledgement = () => {
    if (!props.showAcknowledge) {
      return null;
    }

    if (memo.userAcknowledged) {
      return (
        <Alert className="text-center">
          Acknowledged: {moment(memo.userAcknowledged.acknowledgedAt).fromNow()}
        </Alert>
        // <p style={{ fontSize: "10pt" }}>

        // </p>
      );
    }

    return (
      <Button
        style={{ height: "100%", width: "100%", borderRadius: "5px" }}
        disabled={isLoading}
        onClick={submitAcknowledgeMemo}
      >
        Acknowledge
      </Button>
    );
  };

  const iframeStyle: any = {
    marginTop: "1em",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  return (
    <Container
      style={{
        height: `100%`,
        display: "flex",
        flexDirection: "column",
        maxHeight: "1000px",
      }}
    >
      {/* <Row>
        <Col>
          <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
            {memo.subject}
          </h3>
        </Col>
      </Row> */}
      <Row
        style={{
          flexGrow: 1,
          left: "0px",
          right: "0px",
          bottom: "0px",
          top: "120px",
          position: "fixed",
        }}
      >
        <Col>
          <div
            style={{
              width: "100%",
              height: "calc(100%)",
              position: "relative",
              // paddingBottom: "100%",
              overflow: "hidden",
            }}
          >
            {pdfUrl ? (
              <iframe
                style={iframeStyle}
                title="View Memo"
                src={`/pdfjs-2.9.359-dist/web/viewer.html?file=${pdfUrl}`}
                width={"100%"}
                // height={700}
                scrolling="no"
              />
            ) : (
              <span style={iframeStyle}>
                <LoadingSpinner />
              </span>
            )}
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col>Created At: {new Date(memo.createdAt).toLocaleString()}</Col>
      </Row> */}
      <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
        {/* <Col>
          <Button
            id="PopoverFocus"
            type="button"
            style={{ borderRadius: "5px", width: "100%", height: "100%" }}
          >
            More Info...
          </Button>
        </Col>
        <UncontrolledPopover
          trigger="focus"
          placement="top"
          target="PopoverFocus"
        >
          <PopoverHeader>Memo Details</PopoverHeader>
          <PopoverBody>
            <Row>
              <Col>
                <b>Subject: </b>
                {memo.subject}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Created By: </b>
                {memo.createdByEmail}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Created At: </b>
                {moment(memo.createdAt).format("LLL")}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Body: </b>
                {memo.body}
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover> */}
        <Col>{getAcknowledgement()}</Col>
      </Row>
    </Container>
  );
};
