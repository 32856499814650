import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  CustomInput,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { getMemos } from "../services/Memo";
import { useDispatch } from "react-redux";
import { IUserMemo } from "../Memo";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { LoadingSpinner } from "./Loading/LoadingSpinner";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const globalFilter = (filter: any, row: any, column: any): any => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id]).toUpperCase().indexOf(filter.value.toUpperCase()) > -1
    : true;
};

interface IProps {}
export const MemosList = (props: IProps) => {
  const [memoList, setMemoList] = useState<IUserMemo[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [memos, setMemos] = useState<IUserMemo[]>([]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  /**
   * useEffect componentDidMount
   */
  useEffect(() => {
    const fetchMemos = async () => {
      setIsLoading(true);
      const userMemos = await getMemos();
      setMemos(userMemos ? userMemos : []);
      setMemoList(userMemos ? userMemos : []);
      setIsLoading(false);
    };
    fetchMemos();
  }, [dispatch]);

  function handleIsAcknowledged(acknowledged: Boolean) {
    if (acknowledged) {
      setMemoList(memos.filter((f) => !f.userAcknowledged));
      return;
    }
    setMemoList(memos);
  }

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const memoTable = useMemo(() => {
    return memoList.map((memo: IUserMemo) => {
      const acknowledgedAt = memo.userAcknowledged
        ? memo.userAcknowledged.acknowledgedAt
        : null;

      return {
        createdBy: memo.createdByEmail ? memo.createdByEmail : "",
        createdAt: memo.createdAt,
        subject: memo.subject,
        memoId: memo.memoId,
        classification: memo.classification,
        acknowledgedAt: acknowledgedAt,
      };
    });
  }, [memoList]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Subject",
        accessor: "subject",
        Cell: (props: any) => <span className="number">{props.value}</span>,
        filterMethod: globalFilter,
        Filter: ({ filter, onChange }: any) => {
          return (
            <div style={{ position: "relative" }}>
              <input
                onChange={(event) => onChange(event.target.value)}
                value={filter ? filter.value : ""}
                style={{
                  width: "80%",
                  backgroundColor: "#4f677d",
                  color: "#ffffff",
                }}
              />
              <i
                style={{
                  position: "relative",
                  right: "-5px",
                  lineHeight: "30px",
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </i>
            </div>
          );
        },
      },
      {
        Header: "Class",
        accessor: "classification",
        Cell: (props: any) => (
          <div
            // className={props.value ? "text-success" : "text-danger"}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {props.value ? 
            props.value
             : '--'}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Read",
        accessor: "acknowledgedAt",
        Cell: (props: any) => (
          <div
            className={props.value ? "text-success" : "text-danger"}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {props.value ? (
              <FontAwesomeIcon size="lg" icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faTimesCircle} />
            )}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Created At", // Custom header components!
        accessor: "createdAt",
        show: width > 500,
        Cell: (props: any) => (
          <span className="number">
            {moment(props.value).format("DD-MM-YYYY")}
          </span>
        ),
        filterable: false,
      },
      {
        Header: "Created By",
        accessor: "createdBy", // Custom value accessors!
        show: width > 500,

        Cell: (props: any) => (
          <span className="number" style={{ textAlign: "center" }}>
            {props.value}
          </span>
        ),
        filterable: false,
        // filterMethod: globalFilter,
      },
      // {
      //   Header: "View Memo",
      //   accessor: "memoId",
      //   width: 100,
      //   filterable: false,
      //   sortable: false,
      //   Cell: (props: any) => <Link to={`/memos/${props.value}`}>View</Link>
      // }
    ];
    return baseColumns;
  }, [width]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    // Removed maxWidth={false} prop due to console error.
    // <Container  style={{ textAlign: "center", width: "100%", marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}  >
    <Container style={{ textAlign: "center" }}>
      <Card className="my-3">
        <CardHeader className="h5">
          <b>My Memos</b>
        </CardHeader>
        <Row
          className="my-2"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Col md>
            <Row style={{ alignItems: "center" }}>
              <Col>
                <CustomInput
                  className="my-2"
                  onChange={(e: any) => handleIsAcknowledged(e.target.checked)}
                  type="switch"
                  id="showUnreadSwitch"
                  name="showUnreadSwitch"
                  label="Show only unread"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <CardBody style={{ padding: 0 }}>
          <ReactTable
            className={"-highlight -striped mytableclass"}
            data={memoTable}
            columns={columns}
            defaultPageSize={10}
            filterable
            defaultSorted={[{ id: "createdAt", desc: true }]}
            //Added getTrGorupProps to allow direct selection of the memo via an onClick event handler on the table
            getTrGroupProps={(
              state: any,
              rowInfo: any,
              column: any,
              instance: any
            ) => {
              if (rowInfo !== undefined) {
                return {
                  onClick: (e: any) => {
                    history.push(`/memos/${rowInfo.original.memoId}`);
                  },
                  style: {
                    cursor: "pointer",
                  },
                };
              }
            }}
          />
        </CardBody>
      </Card>
      {/* <Row>
        <Col>
          <h1>Memos</h1>
        </Col>
      </Row> */}
    </Container>
  );
};
