import { makeRequest, isLocal } from "../api";

export const auditUrl = isLocal
  ? "https://d4q4cgunv3.execute-api.ca-central-1.amazonaws.com/dev/"
  : "https://rwn3bryfae.execute-api.ca-central-1.amazonaws.com/prod/";

export const getMemoAudit = async (memoId: string) => {
    try {
        const response: string[] = await makeRequest(
        `audit/memo/${memoId}`,
        "GET",
        null,
        auditUrl
        );
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getCustomerMemosAudit = async () => {
    try {
        const response: string[] = await makeRequest(
        `audit`,
        "GET",
        null,
        auditUrl
        );
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}