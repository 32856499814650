import { makeRequest, isLocal } from "../api";
import { IMemoEmailRecipient } from "../../Memo";

export interface IAssignRoles {
  email: string;
  userId: string;
  roles: string[];
}

export const adminUrl = isLocal
  ? "https://s9emn1l3y8.execute-api.ca-central-1.amazonaws.com/dev/"
  : "https://lz29gu4np4.execute-api.ca-central-1.amazonaws.com/prod/";

export const getUserRoles = async (userId: string, email: string) => {
  try {
    const response: string[] = await makeRequest(
      `user/${userId}/roles?email=${email}`,
      "GET",
      null,
      adminUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const setUsersRoles = async (
  users: IMemoEmailRecipient[],
  roles: string[]
) => {
  const promises: Promise<IAssignRoles>[] = [];
  try {
    for (const user of users) {
      const response: Promise<IAssignRoles> = makeRequest(
        `user/${user.userId}/roles`,
        "PUT",
        {
          userId: user.userId,
          email: user.email,
          roles
        },
        adminUrl
      );
      promises.push(response);
    }

    const result = await Promise.all(promises);
    return result;
  } catch (error) {
    console.error(error);
    debugger;
    return null;
  }
};
