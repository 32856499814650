import React, { useState, useMemo } from "react";
import {
  Container,
  FormGroup,
  Form,
  Label,
  Col,
  Row,
  Button,
  Spinner,
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { MicrosoftUsersSelect } from "../../components/FormComponents/MicrosoftUsersSelect";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { addUserToMemos } from "../../services/Memo";
import { IMemoEmailRecipient } from "../../Memo";

export const AddUserToMemos = () => {
  const [users, setUsers] = useState<IMemoEmailRecipient[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [date, setDate] = useState<Date>(new Date());

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<any>(null);

  const handleChange = (newDate: Date) => {
    setDate(newDate);
  };

  const addUserToMemosAction = async () => {
    setIsLoading(true);
    const userToAdd: IMemoEmailRecipient = {
      userId: users[0].userId,
      email: users[0].email,
    };
    const response = await addUserToMemos(userToAdd, groups[0], date);
    setApiResponse(response);
    setIsLoading(false);
  };

  const isFormValid = useMemo(() => {
    return groups.length === 1 && users.length === 1 && date;
  }, [groups, users, date]);

  const alertMessage = useMemo(() => {
    if (!apiResponse) {
      return "";
    }
    return apiResponse.success
      ? `Successfully added user to Memo Group`
      : `Could not add User to Memo Group: ${apiResponse.data}`;
  }, [apiResponse]);

  return (
    <Container>
      <Card className="my-3">
        <CardHeader>Add User to Memo Group</CardHeader>
        <CardBody>
          <Form style={{ paddingBottom: "1rem" }}>
            <div style={{ margin: "2rem" }}>
              {/* Group */}
              <FormGroup row>
                <Label for="memoRecipients" sm={2}>
                  Group
                </Label>
                <Col sm={10}>
                  <MicrosoftUsersSelect
                    setGroups={setGroups}
                    showGroups={true}
                    multi={false}
                  />
                </Col>
              </FormGroup>
              {/* User */}
              <FormGroup row>
                <Label for="memoUser" sm={2}>
                  User
                </Label>
                <Col sm={10}>
                  <MicrosoftUsersSelect
                    setMemoRecipients={setUsers}
                    showGroups={false}
                    multi={false}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="forDate" sm={2}>
                  For Date
                </Label>
                <Col sm={10}>
                  <DatePicker
                    selected={date}
                    onChange={handleChange}
                    showTimeSelect
                    dateFormat="Pp"
                  />
                </Col>
              </FormGroup>

              {/* Submit and alert */}
              <Row style={{ marginTop: "2rem" }}>
                <Col sm={{ size: 4, offset: 4 }}>
                  {!apiResponse && (
                    <Button
                      onClick={addUserToMemosAction}
                      color="primary"
                      block
                      disabled={!isFormValid}
                    >
                      {isLoading ? (
                        <Spinner color="light" />
                      ) : (
                        <div>Add user to Memo Group</div>
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "1em" }}>
                <Col>
                  {apiResponse ? (
                    <UncontrolledAlert
                      color={apiResponse.success ? "success" : "danger"}
                    >
                      {alertMessage}
                    </UncontrolledAlert>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};
