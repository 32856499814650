import { adminUrl } from "./assignPermissions";
import { makeRequest } from "../api";

export const getTermsAndConditions = async (
  userId: string
): Promise<boolean> => {
  try {
    const response = await makeRequest(
      `customerTermsAndConditions/${userId}`,
      "GET",
      null,
      adminUrl
    );
    return response.showTermsAndConditions;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const setTermsAndConditions = async (
  userId: string,
  accepted: boolean
) => {
  try {
    const response = await makeRequest(
      `customerTermsAndConditions`,
      "PUT",
      { accepted },
      adminUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};
