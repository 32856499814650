import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Button, Row, Col, Input } from "reactstrap";
import { acknowledgeMemoOnBehalf, addUserToMemo } from "../services/Memo";
import { IMemoEmailRecipient } from "../Memo";
import {
  listMemoRecipients,
  IMemoRecipient,
} from "../services/Memos/FetchMemoRecipients";
import { LoadingSpinner } from "./Loading/LoadingSpinner";
import ReactTable from "react-table";
import uuid from "uuid";
import { MicrosoftUsersSelect } from "./FormComponents/MicrosoftUsersSelect";

interface IProps {
  memoId: string;
  // isShowOnlyAcknowledged: boolean;
}
export const CreatedMemoDetails = (props: IProps) => {
  const [newMemoRecipients, setNewMemoRecipients] = useState<
    IMemoEmailRecipient[]
  >([]);
  const [recipients, setRecipients] = useState<IMemoRecipient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { memoId } = props;

  const [isShowOnlyAcknowledged, setIsShowOnlyAcknowledged] = useState(false);

  const fetchMemoRecipients = useCallback(async () => {
    setIsLoading(true);
    const recipients = await listMemoRecipients(memoId);
    setRecipients(recipients ? recipients : []);
    setIsLoading(false);
  }, [memoId]);

  useEffect(() => {
    fetchMemoRecipients();
    return () => {};
  }, [memoId, fetchMemoRecipients]);

  const acknowledgeMemoOnBehalfAction = useCallback(
    (userId: string) => async () => {
      setIsLoading(true);
      await acknowledgeMemoOnBehalf(memoId, userId);
      await fetchMemoRecipients();
      setIsLoading(false);
    },
    [fetchMemoRecipients, memoId]
  );

  const addRecipientToMemo = (memoId: string) => async () => {
    setIsLoading(true);
    await addUserToMemo(memoId, newMemoRecipients);
    await fetchMemoRecipients();
    setIsLoading(false);
  };

  const memoDetailsTable = useMemo(() => {
    const tableRows: any[] = [];
    for (const recipient of recipients) {
      const userAcknowledged = recipient.userAcknowledged;
      if (isShowOnlyAcknowledged && userAcknowledged) {
        continue;
      }

      tableRows.push({
        userId: recipient.recipientId,
        email: recipient.email,
        emailSent: recipient.emailSent ? "True" : "False",
        userAcknowledged: userAcknowledged
          ? new Date(userAcknowledged.acknowledgedAt).toISOString()
          : false,
      });
    }
    return tableRows;
  }, [isShowOnlyAcknowledged, recipients]);

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: (props: any) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Email Sent", // Custom header components!
        accessor: "emailSent",
        filterable: false,
      },
      {
        Header: "Acknowledged At",
        accessor: "userAcknowledged",
        filterable: false,
        Cell: (props: any) => {
          const { userAcknowledged, userId } = props.original;
          return userAcknowledged ? (
            userAcknowledged
          ) : (
            <Button
              style={{ height: "100%", fontSize: "0.8rem" }}
              onClick={acknowledgeMemoOnBehalfAction(userId)}
            >
              Acknowledge on Behalf
            </Button>
          );
        },
      },
    ],
    [acknowledgeMemoOnBehalfAction]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container
      style={{
        backgroundColor: "aliceblue",
        color: "black",
        paddingTop: "1rem",
      }}
    >
      <Row style={{ margin: "2em" }}>
        <Col sm>
          <MicrosoftUsersSelect
            setMemoRecipients={setNewMemoRecipients}
            showGroups={false}
            multi={false}
          />
        </Col>
        <Col sm={2}>
          <Button
            block
            disabled={isLoading}
            onClick={addRecipientToMemo(memoId)}
          >
            Add to Memo
          </Button>
        </Col>
        <Col sm>
          Only Unacknowledged:
          <Input
            type={"checkbox"}
            onChange={(e: any) => setIsShowOnlyAcknowledged(e.target.checked)}
            style={{ marginLeft: "1rem" }}
            defaultChecked={isShowOnlyAcknowledged}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable
            className={"-highlight -striped"}
            loading={isLoading}
            defaultPageSize={15}
            data={memoDetailsTable}
            filterable
            columns={columns}
            showPageSizeOptions={false}
            getTrProps={(state: any, rowInfo: any) => {
              const key = rowInfo ? rowInfo.original.userId : uuid.v1();
              if (!rowInfo) {
                return {
                  key,
                };
              }
              const color = rowInfo.original.userAcknowledged
                ? "#5cb85c"
                : "#d9534f";
              return {
                key,
                style: {
                  backgroundColor: color,
                  height: "2.5rem",
                },

                // style: {
                //   backgroundColor: userAcknowledged ?
                // },
              };
            }}
            // filterable
            // defaultSorted={[{ id: "createdAt", desc: true }]}
          />
        </Col>
      </Row>

      {/* <Row>
        <Col>
          <ListGroupItemHeading>User</ListGroupItemHeading>
        </Col>
        <Col>
          <ListGroupItemHeading>Email Sent</ListGroupItemHeading>
        </Col>
        <Col>
          <ListGroupItemHeading>Acknowledged</ListGroupItemHeading>
        </Col>
      </Row>
      <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
        {recipients.map((recipient: IMemoRecipient) => {
          const userAcknowledged = recipient.userAcknowledged;
          const color = userAcknowledged ? "success" : "danger";
          const acknowledgedAt = userAcknowledged ? (
            new Date(userAcknowledged.acknowledgedAt).toISOString()
          ) : (
            <Button
              onClick={acknowledgeMemoOnBehalfAction(recipient.recipientId)}
            >
              Acknowledge on Behalf
            </Button>
          );
          return (
            <ListGroupItem
              key={recipient.recipientId}
              color={color}
              style={{ marginTop: "0.5rem" }}
            >
              <Row>
                <Col>{recipient.email}</Col>
                <Col>{recipient.emailSent ? "True" : "False"}</Col>
                <Col>{acknowledgedAt}</Col>
              </Row>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <Row style={{ margin: "1em" }}>
        <Col>
          <MicrosoftUsersSelect
            setMemoRecipients={setNewMemoRecipients}
            showGroups={false}
            multi={false}
          />
        </Col>
        <Col sm={2}>
          <Button
            block
            disabled={isLoading}
            onClick={addRecipientToMemo(memoId)}
          >
            Add User
          </Button>
        </Col>
      </Row> */}
    </Container>
  );
};
