import React, { useState, useMemo } from "react";
import {
  Table,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { createMemo } from "../services/Memo";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers";
import { MicrosoftUsersSelect } from "../components/FormComponents/MicrosoftUsersSelect";
import { IMemoEmailRecipient } from "../Memo";
import { IResponse } from "../services/api";
import { Prompt } from "react-router";
import Select from "react-select";

interface IProps {}

const reminderDaysOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "1 day" },
  { value: 2, label: "2 days" },
  { value: 3, label: "3 days" },
  { value: 5, label: "5 days" },
  { value: 10, label: "10 days" },
];

const classificationOptions = [
  {value: "General Memo", label: "General Memo"},
  {value: "Operational Directive", label: "Operational Directive"},

];

const CreateMemo = (props: IProps) => {
  const [memoRecipients, setMemoRecipients] = useState<IMemoEmailRecipient[]>(
    []
  );
  const [groups, setGroups] = useState<string[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [createMemoResponse, setCreateMemoResponse] =
    useState<IResponse | null>(null);
  const user = useSelector((state: IRootState) => state.user.user);

  const [memoFile, setMemoFile] = useState<File | null>(null);

  const [classification, setClassification] = useState<string>("General Memo");

  const [subject, setSubject] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [reminderDays, setReminderDays] = useState<number>(0);

  const onUploadProgress = (progressEvent: any) => {
    console.dir(progressEvent, { depth: null });
  };

  const createMemoAction = async () => {
    if (!user) {
      console.log("You must be signed in to create a memo!");
      return;
    }

    if (!memoFile || !body || !subject || memoRecipients.length === 0) {
      console.log("Invalid memo to create!");
      return;
    }

    setIsCreating(true);

    const response = await createMemo(
      {
        body,
        subject,
        classification,
        recipients: memoRecipients,
        groups,
        reminderDays,
      },
      memoFile,
      onUploadProgress
    );
    setCreateMemoResponse(response);
    setIsCreating(false);
  };

  const handleFileChange = ({ target }: { target: HTMLInputElement }) => {
    if (!target.files) {
      return;
    }
    setMemoFile(target.files[0]);
  };

  const handleTextChange =
    (stateFunc: (value: string) => void) =>
    ({ target }: { target: HTMLInputElement }) => {
      stateFunc(target.value);
    };

  const isFormValid = useMemo(() => {
    return (
      memoRecipients.length > 0 &&
      subject.length > 0 &&
      body.length > 0 &&
      memoFile
    );
  }, [memoRecipients, subject, body, memoFile]);

  const alertMessage = useMemo(() => {
    if (!createMemoResponse) {
      return "";
    }
    return createMemoResponse.success
      ? `Successfully created Memo: ${createMemoResponse.data.subject}`
      : `Could not Create memo: ${createMemoResponse.data}`;
  }, [createMemoResponse]);

  return (
    <Container>
      {isCreating && (
        <Prompt
          message={(location) =>
            `Memos is still uploading, are you sure you want to leave?`
          }
        />
      )}
      <Card className="my-3">
        <CardHeader>Create Memo</CardHeader>
        <CardBody>
          <Form style={{ paddingBottom: "1rem" }}>
            <div style={{ margin: "2rem" }}>
              {/* Recipients */}
              <FormGroup row>
                <Label for="memoRecipients" sm={2}>
                  Recipients
                </Label>
                <Col sm={10}>
                  <MicrosoftUsersSelect
                    setGroups={setGroups}
                    setMemoRecipients={setMemoRecipients}
                    showGroups={true}
                    multi={true}
                  />
                </Col>
              </FormGroup>

              {/* Classification */}
              <FormGroup row>
                <Label for="memoDepartment" sm={2}>
                  Classification
                </Label>
                <Col sm={10} style={{ color: "black" }}>
                  <Select
                  options={classificationOptions}
                  defaultValue={classificationOptions[0]}
                  onChange={(option: any) => {
                    setClassification(option.value)
                  }}

                  />
                </Col>
              </FormGroup>
              {/* Subject */}
              <FormGroup row>
                <Label for="memoSubject" sm={2}>
                  Subject
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="text"
                    id="memoSubject"
                    value={subject}
                    onChange={handleTextChange(setSubject)}
                  />
                </Col>
              </FormGroup>

              {/* Body */}
              <FormGroup row>
                <Label for="memoBody" sm={2}>
                  Body
                </Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    name="text"
                    id="memoBody"
                    value={body}
                    onChange={handleTextChange(setBody)}
                  />
                </Col>
              </FormGroup>

              {/* Memo PDF */}
              <FormGroup row>
                <Label for="exampleFile" sm={2}>
                  File
                </Label>
                <Col sm={10}>
                  <Input
                    onChange={handleFileChange}
                    type="file"
                    name="file"
                    id="exampleFile"
                  />
                  <FormText color="muted">Insert PDF File as memo</FormText>
                </Col>
              </FormGroup>

              {/* Reminder Days */}
              <FormGroup row>
                <Label for="exampleFile" sm={2}>
                  Reminder Days
                </Label>
                <Col sm={10} style={{ color: "black" }}>
                  <Select
                    options={reminderDaysOptions}
                    defaultValue={reminderDaysOptions[0]}
                    onChange={(option: any) => {
                      setReminderDays(option.value);
                    }}
                  />
                </Col>
              </FormGroup>

              {/* Create Memo */}
              <Row style={{ marginTop: "2rem" }}>
                <Col sm={{ size: 4, offset: 4 }}>
                  {isFormValid && (
                    <Button
                      style={{ borderRadius: "10px" }}
                      onClick={createMemoAction}
                      color="primary"
                      block
                      // disabled={!isFormValid}
                    >
                      {isCreating ? (
                        <Spinner color="light" />
                      ) : (
                        <div>Create memo!</div>
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "1em" }}>
                <Col>
                  {createMemoResponse ? (
                    <UncontrolledAlert
                      color={createMemoResponse.success ? "success" : "danger"}
                    >
                      {alertMessage}
                    </UncontrolledAlert>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th scope="col">email (Count: {memoRecipients.length})</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: 100, overflow: "auto" }}>
              {memoRecipients.map(function (
                recipient: IMemoEmailRecipient,
                index: number
              ) {
                return (
                  <tr key={index}>
                    <td>{recipient.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateMemo;

/**
 * import React, { useRef, useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "reactstrap";
// import LoaderButton from "../components/LoaderButton";
// import config from "../config";
import "./NewNote.css";

interface IProps {}
export default function NewMemo(props: IProps) {
  const file = useRef(null);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);
  }

  return (
    <div className="NewNote">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="content">
          <FormControl
            value={content}
            componentClass="textarea"
            onChange={e => setContent(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="file">
          <ControlLabel>Attachment</ControlLabel>
          <FormControl onChange={handleFileChange} type="file" />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
    </div>
  );
}
 */
