import { IUser, IUserMap, IUserGroupMap } from "../reducers/UserReducer";

export enum Type {
  SET_USER = 'SET_USER',
  SET_USER_ROLES = 'SET_USER_ROLES',
  SET_USERS_AND_GROUPS = 'SET_USERS_AND_GROUPS'
}

interface ISetUser {
  type: Type.SET_USER;
  user: IUser | null;
}

interface ISetUserRoles {
  type: Type.SET_USER_ROLES;
  roles: string[];
}

interface IGotUsers {
  type: Type.SET_USERS_AND_GROUPS;
  userMap: IUserMap;
  userGroupMap: IUserGroupMap;
}

export type Action =
  | ISetUser
  | ISetUserRoles
  | IGotUsers;

export const setUser = (user: IUser) => {
  return { type: Type.SET_USER, user }
}

export const setUserRoles = (roles: string[]) => {
  return { type: Type.SET_USER_ROLES, roles }
}

export const setUsersAndGroups = (users: IUser[], userGroups: any[]) => {
  const userMap: IUserMap = {}
  users.forEach((user: IUser) => {
    userMap[user.id] = user;
  })

  const userGroupMap: IUserGroupMap = {}
  userGroups.forEach((userGroup) => {
    userGroupMap[userGroup.id] = userGroup;
  })
  return { type: Type.SET_USERS_AND_GROUPS, userMap, userGroupMap };
}