// var graph = require('@microsoft/microsoft-graph-client');

import * as graph from "@microsoft/microsoft-graph-client";
import { AuthResponse } from "msal";
import {
  PageCollection,
  PageIteratorCallback,
  PageIterator,
} from "@microsoft/microsoft-graph-client";
import { getAccessToken } from "./UserAgent";

const getAuthenticatedClient = (accessToken: AuthResponse) => {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    },
  });

  return client;
};

export const getUserDetails = async (accessToken: AuthResponse) => {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api("/me").get();
  return user;
};

export const getUserProfilePic = async (): Promise<string | null> => {
  const accessToken = await getAccessToken();
  if (!accessToken) {
    return null;
  }
  const client = getAuthenticatedClient(accessToken);

  const photos = await client.api("/me/photos/120x120/$value").get();
  return photos ? photos : null;
};

export const getEvents = async (accessToken: AuthResponse) => {
  const client = getAuthenticatedClient(accessToken);

  const events = await client
    .api("me/events")
    .select("subject,organizer,start,end")
    .orderby("createdDateTime DESC")
    .get();
  return events;
};

export const getUsers = async () => {
  const accessToken = await getAccessToken();
  if (!accessToken) {
    console.error("couldn't get accessToken", accessToken);
    return [];
  }
  const users: any[] = [];
  const client = getAuthenticatedClient(accessToken);
  let nextLink: string | undefined = undefined;
  let skipToken: string | undefined = undefined; // Place to store the skipToken returned from the graph service
  do {
    let usersUrl = "";

    if (skipToken) {
      usersUrl = `users/?$skiptoken=${skipToken}`;
    }

    if (!skipToken) {
      usersUrl = `users/`;
    }
    const response = await client.api(usersUrl).get();
    nextLink = response["@odata.nextLink"];
    users.push(...response.value);

    if (nextLink) {
      skipToken = nextLink.split("=")[1];
    } else skipToken = undefined;
  } while (nextLink);

  return users;
};

export const getUserGroups = async (accessToken: AuthResponse) => {
  const client = getAuthenticatedClient(accessToken);
  const groupIds = await client.api("/groups").get();

  const promises: Promise<any>[] = groupIds.value.map((group: any) => {
    return client.api(`/groups/${group.id}`).get();
  });

  const groups = await Promise.all(promises);
  return groups;
};

export const listFilteredGroups = async (
  accessToken: AuthResponse,
  filteredInput: string
) => {
  const groups: any[] = [];
  try {
    const client = getAuthenticatedClient(accessToken);
    const response: PageCollection = await client
      .api("/groups")
      .filter(`startsWith(displayName, '${filteredInput}')`)
      .get();

    let callback: PageIteratorCallback = (data) => {
      groups.push({
        label: data.displayName,
        value: data.id,
      });
      return true;
    };

    // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
    let pageIterator = new PageIterator(client, response, callback);
    // This iterates the collection until the nextLink is drained out.
    await pageIterator.iterate();
    return groups;
  } catch (e) {
    return [];
  }
  // const promises: Promise<any>[] = [];
  // promises.push(getFilteredGroups(accessToken, filteredInput));
  // promises.push(getFilteredUsers(accessToken, filteredInput));
  // const [groups, users] = await Promise.all(promises);

  // return [...groups, ...users];
};

// const getFilteredGroups = async (
//   accessToken: AuthResponse,
//   filteredInput: string
// ) => {
//   const groups: any[] = [];
//   try {
//     const client = getAuthenticatedClient(accessToken);
//     const response: PageCollection = await client
//       .api("/groups")
//       .filter(`startsWith(displayName, '${filteredInput}')`)
//       .get();

//     let callback: PageIteratorCallback = data => {
//       groups.push({
//         label: data.displayName,
//         value: data.id,
//       });
//       return true;
//     };

//     // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
//     let pageIterator = new PageIterator(client, response, callback);
//     // This iterates the collection until the nextLink is drained out.
//     await pageIterator.iterate();
//     return groups;
//   } catch (e) {
//     return [];
//   }
// };

export const listFilteredUsers = async (
  accessToken: AuthResponse,
  filteredInput: string
) => {
  const groups: any[] = [];
  try {
    const client = getAuthenticatedClient(accessToken);
    const response: PageCollection = await client
      .api("/users")
      .filter(`startsWith(mail, '${filteredInput}')`)
      .get();

    let callback: PageIteratorCallback = (data) => {
      groups.push({
        label: data.mail,
        value: data.id,
      });
      return true;
    };

    // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
    let pageIterator = new PageIterator(client, response, callback);
    // This iterates the collection until the nextLink is drained out.
    await pageIterator.iterate();
    return groups;
  } catch (e) {
    return [];
  }
};

export const listUsers = async (accessToken: AuthResponse) => {
  const client = getAuthenticatedClient(accessToken);
  const users = await client
    .api("/users")
    // .select('displayName,mail')
    .get();
  return users.value;
};

export interface IOption {
  value: string;
  label: string;
}
export const listMembersOfGroup = async (
  groupId: string,
  accessToken: AuthResponse
) => {
  const client = getAuthenticatedClient(accessToken);
  const response: PageCollection = await client
    .api(`/groups/${groupId}/transitiveMembers`)
    // .select('displayName,mail')
    .get();

  const members: IOption[] = [];
  let callback: PageIteratorCallback = (data) => {
    if (data["@odata.type"] !== "#microsoft.graph.user") {
      return true;
    }
    members.push({
      value: data.id,
      label: data.mail,
    });
    return true;
  };

  // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
  let pageIterator = new PageIterator(client, response, callback);
  // This iterates the collection until the nextLink is drained out.
  await pageIterator.iterate();
  return members;
};
