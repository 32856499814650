import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { LoadingSpinner } from "../Loading/LoadingSpinner";
import { setTermsAndConditions } from "../../services/Admin/termsAndConditions";

interface IProps {
  userId: string;
}
export const ShowTermsAndConditions = (props: IProps) => {
  const [isLoading] = useState<boolean>(false);

  const submitTermsAndConditions = (accepted: boolean) => async () => {
    await setTermsAndConditions(props.userId, accepted);
    window.location.reload(true);
  };

  const pdfUrl = "/Colert_Terms_Of_Use.pdf";
  const iframeStyle: any = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  };

  return (
    <Container
      style={{
        height: `100%`,
        display: "flex",
        flexDirection: "column",
        maxHeight: "1000px"
      }}
    >
      <Row>
        <Col>
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            Terms Of Use
          </h1>
        </Col>
      </Row>
      <Row style={{ flexGrow: 1 }}>
        <Col>
          <div
            style={{
              width: "100%",
              height: "calc(100%)",
              position: "relative",
              // paddingBottom: "100%",
              overflow: "hidden"
            }}
          >
            {pdfUrl ? (
              <iframe
                style={iframeStyle}
                title="View Memo"
                src={`/pdfjs-2.8.335-dist/web/viewer.html?file=${pdfUrl}`}
                width={"100%"}
                // height={700}
                scrolling="no"
              />
            ) : (
              <span style={iframeStyle}>
                <LoadingSpinner />
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
        <Col>
          <Button
            style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            disabled={isLoading}
            color="danger"
            onClick={submitTermsAndConditions(false)}
          >
            Decline
          </Button>
        </Col>
        <Col>
          <Button
            style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            disabled={isLoading}
            color="success"
            onClick={submitTermsAndConditions(true)}
          >
            Agree
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
