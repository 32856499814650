import { UserAgentApplication } from "msal";
import config from "../Config";

let userAgentApplication: UserAgentApplication | null = null;

export const getUserAgentApplication = () => {
  if (!userAgentApplication) {
    userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.appId,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    });
  }
  return userAgentApplication;
};

export const getAccessToken = async () => {
  const userAgent = getUserAgentApplication();
  try {
    const accessToken = await userAgent.acquireTokenSilent({
      scopes: config.scopes,
    });
    return accessToken;
  } catch (error) {
    if (error.errorMessage.indexOf("interaction_required") !== -1) {
      try {
        return await userAgent.acquireTokenPopup({
          scopes: config.scopes,
          prompt: "login",
        });
      } catch (error) {
        console.log(error);
        alert(error);
        return null;
      }
    }
    console.log(error);
  }
};
