import React, { useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { AssignPermissions } from "../components/admin/AssignPermissions";
import { CustomerMemosAudit } from "../components/admin/CustomerMemosAudit";

interface IProps {}
export const AdminPage = (props: IProps) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container style={{ marginTop: "1rem" }}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Permissions
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Audit
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent style={{ marginTop: "1rem" }} activeTab={activeTab}>
        <TabPane tabId="1">
          <AssignPermissions />
        </TabPane>
        <TabPane tabId="2">
          <CustomerMemosAudit />
        </TabPane>
        {/* <TabPane tabId="2">
          <Container>
            <Row>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
            </Row>
          </Container>
        </TabPane> */}
      </TabContent>
    </Container>
  );
};
