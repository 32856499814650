import { getUserAgentApplication, getAccessToken } from "./UserAgent";
import config from "../Config";
import { getUserDetails } from "./GraphService";
import { IUser } from "../redux/reducers/UserReducer";

interface IError {
  errorMessage: string;
  debug?: string;
}

export const isUserError = (object: any): object is IError => {
  return "errorMessage" in object && "debug" in object;
};

export const getUserProfile = async (): Promise<IUser | IError> => {
  try {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const user = await getUserDetails(accessToken);
      return user;
    }

    return {
      errorMessage: "Could not obtain access token!",
    };
  } catch (error) {
    if (typeof error === "string") {
      return { errorMessage: error };
    } else {
      return { errorMessage: error.toString() };
    }
  }
};

export const login = async (): Promise<IUser | IError> => {
  const userAgentApplication = getUserAgentApplication();
  try {
    await userAgentApplication.loginPopup({
      scopes: config.scopes,
      prompt: "select_account",
    });
    const profile = await getUserProfile();
    return profile;
  } catch (err) {
    let error: IError | null = null;

    if (typeof err === "string") {
      const errParts = err.split("|");
      error =
        errParts.length > 1
          ? { errorMessage: errParts[1], debug: errParts[0] }
          : { errorMessage: err };
    } else {
      error = {
        errorMessage: err.message,
        debug: JSON.stringify(err),
      };
    }
    return error;
  }
};

export const logout = () => {
  const userAgentApplication = getUserAgentApplication();
  localStorage.setItem("colert-token", "");
  userAgentApplication.logout();
};
