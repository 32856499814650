import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";

import { makeUnauthorizedRequest } from "../services/api";
import { acknowledgeMemoFromToken } from "../services/Memo";
import { Memo, IMemoProps } from "../components/Memo";
import { LoadingSpinner } from "../components/Loading/LoadingSpinner";
import { login, isUserError } from "../services/UserService";
import { setUser } from "../redux/actions/UserActions";
import { Container, Row, Col, Button, Jumbotron } from "reactstrap";
import { useDispatch } from "react-redux";

interface IProps {}

/**
 * UniqueMemo React Component that retrieves a memo based on a unique JWT sent to
 * an unauthorized user via email
 * @param props structure of the props passed into UniqueMemo
 */
export const UniqueMemo = (props: IProps) => {
  const { memoJWT } = useParams();
  const dispatch = useDispatch();
  const [uniqueMemo, setUniqueMemo] = useState<IMemoProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const getUniqueMemo = useCallback(async () => {
    setIsLoading(true);
    const response = await makeUnauthorizedRequest(
      `uniqueMemo/${memoJWT}`,
      "GET"
    );

    setIsLoading(false);
    if (response.error) {
      setError(response);
      return;
    }
    setUniqueMemo(response);
  }, [memoJWT]);

  useEffect(() => {
    if (!memoJWT) {
      return;
    }

    getUniqueMemo();
  }, [memoJWT, getUniqueMemo]);

  const acknowledgeMemo = async (memoId: string) => {
    if (!memoJWT) {
      return null;
    }
    setIsLoading(true);
    await acknowledgeMemoFromToken(memoId, memoJWT);
    await getUniqueMemo();
    setIsLoading(false);
  };

  const handleLogin = async () => {
    const response = await login();
    if (!isUserError(response)) {
      dispatch(setUser(response));
    }
  };

  if (error) {
    return (
      <Container
        style={{
          height: `100%`,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Jumbotron>
          <Row>
            <Col>
              <h2>Your unique Memo link has Expired!</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Please sign in to view the memo</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="primary" onClick={handleLogin}>
                Click here to sign in
              </Button>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    );
  }
  if (!uniqueMemo || isLoading) {
    return <LoadingSpinner />;
  }

  const { memo, memoUrl } = uniqueMemo;
  return (
    <Memo
      memo={memo}
      showAcknowledge={true}
      memoUrl={memoUrl}
      acknowledgeMemo={acknowledgeMemo}
      isLoading={isLoading}
    />
  );
};
