import { IMemoMap } from "../../../../shared/Memo";


export enum Type {
  ADD_MEMO = 'ADD_MEMO',
  GET_MEMOS = 'GET_MEMOS'
}

interface IAddMemo {
  type: Type.ADD_MEMO;
  memo: IMemoMap;
}

interface IGetMemos {
  type: Type.GET_MEMOS;
  memos: IMemoMap;
}

export type Action =
  | IAddMemo
  | IGetMemos;

export const getMemosAction = (memos: IMemoMap) => {
  return {type: Type.GET_MEMOS, memos}
}