import React, { useState, useMemo, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers";
import {
  IUser,
  /*hasAdminRole, hasRecipientRole,*/
  hasAdminRole,
  hasSenderRole,
} from "../redux/reducers/UserReducer";
import { getUserProfilePic } from "../services/GraphService";

interface IAvatarProps {
  src: string | null;
}
function UserAvatar(props: IAvatarProps) {
  // If a user avatar is available, return an img tag with the pic
  if (props.src) {
    return (
      <img
        src={props.src}
        alt="user"
        className="rounded-circle align-self-center mr-2"
        style={{ width: "32px" }}
      ></img>
    );
  }

  // No avatar available, return a default icon
  return (
    <i
      className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
      style={{ width: "32px" }}
    ></i>
  );
}

interface IAuthProps extends IProps {
  roles: string[];
  user: IUser | null;
}
const AuthNavItem = (props: IAuthProps) => {
  const [profilePicSrc, setProfilePicSrc] = useState<string | null>(null);
  const { user } = props;
  useEffect(() => {
    const fetchProfilPic = async () => {
      if (user) {
        const profilePic = await getUserProfilePic();
        if(profilePic) {

          const picUrl = URL.createObjectURL(profilePic);
          setProfilePicSrc(picUrl);
        }
      }
    };
    fetchProfilPic();
    return () => {};
  }, [user]);
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.roles.length > 0 && props.user) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <UserAvatar src={profilePicSrc} />
        </DropdownToggle>
        <DropdownMenu right>
          <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
          <p className="dropdown-item-text text-muted mb-0">
            {props.user.mail}
          </p>
          <DropdownItem divider />
          <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <NavLink onClick={props.authButtonMethod}>Sign In</NavLink>
    </NavItem>
  );
};

interface IProps {
  authButtonMethod: () => Promise<void>;
}

const NavBar = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (event: React.MouseEvent) => {
    setIsOpen(!isOpen);
  };

  const roles = useSelector((state: IRootState) => state.user.roles);
  const user = useSelector((state: IRootState) => state.user.user);
  const { authButtonMethod } = props;

  const authLinks = useMemo(() => {
    const createMemoNavItem = hasSenderRole(roles) ? (
      <NavItem>
        <RouterNavLink to="/createMemo" className="nav-link" exact>
          Create Memo
        </RouterNavLink>
      </NavItem>
    ) : null;

    const createdMemosNavItem = hasSenderRole(roles) ? (
      <NavItem>
        <RouterNavLink to="/createdMemos" className="nav-link" exact>
          Created Memos
        </RouterNavLink>
      </NavItem>
    ) : null;

    const addUserToMemosNavItem = hasSenderRole(roles) ? (
      <NavItem>
        <RouterNavLink to="/addUserToMemos" className="nav-link" exact>
          Add User To Memos
        </RouterNavLink>
      </NavItem>
    ) : null;

    const adminNavItem = hasAdminRole(roles) ? (
      <NavItem>
        <RouterNavLink to="/admin" className="nav-link" exact>
          Admin
        </RouterNavLink>
      </NavItem>
    ) : null;

    return (
      <>
        {createMemoNavItem}
        {createdMemosNavItem}
        {addUserToMemosNavItem}
        {adminNavItem}
      </>
    );
  }, [roles]);

  return (
    <div>
      <Navbar color="dark" dark expand="md" fixed="top">
        <Container>
          <NavbarBrand href="/">
            <img alt="Colert" src={"/thumbnail.png"} width="50" height="50" />
            <b style={{ marginLeft: "0.5rem" }}>Colert</b>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <RouterNavLink to="/" className="nav-link" exact>
                  Home
                </RouterNavLink>
              </NavItem>
              {authLinks}
            </Nav>
            <Nav className="justify-content-end" navbar>
              <AuthNavItem
                roles={roles}
                user={user}
                authButtonMethod={authButtonMethod}
              />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
