import React from "react";
import { WelcomeContent } from "../components/WelcomeContent";
import { MemosList } from "../components/MemosList";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers";

import { hasRecipientRole } from "../redux/reducers/UserReducer";

interface IProps {
  authButtonMethod: () => Promise<void>;
}

const Welcome = (props: IProps) => {
  const { authButtonMethod } = props;
  const roles = useSelector((state: IRootState) => state.user.roles);
  return (
    <>
      <WelcomeContent authButtonMethod={authButtonMethod} />

      {hasRecipientRole(roles) ? <MemosList /> : null}
    </>
  );
};

export default Welcome;
