import { makeRequest, makeTokenRequest, IResponse, isLocal } from "./api";
import { IUserMemo, INewMemo, IMemoEmailRecipient } from "../../../shared/Memo";

import axios, { AxiosRequestConfig } from "axios";

const memoPDFUrl = isLocal
  ? "https://i4w48os0eg.execute-api.ca-central-1.amazonaws.com/dev/"
  : "https://u0uvai1jqj.execute-api.ca-central-1.amazonaws.com/prod/";

console.log("IS LOCAL", isLocal);
export const createMemo = async (
  newMemo: INewMemo,
  file: File,
  onUploadProgress: (progressEvent: any) => void
): Promise<IResponse> => {
  try {
    const data = { newMemo: { ...newMemo } };
    // const response = await makeRequest("memos", "POST", data);
    const baseMemo = await makeRequest(`memos`, "POST", data, memoPDFUrl);

    const sendRecipientsData = { newMemo, memo: baseMemo.memo };
    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/pdf",
      },
      onUploadProgress,
    };
    const uploadResponse = await axios.put(
      baseMemo.memoUrl.uploadURL,
      file,
      options
    );
    if (uploadResponse.status !== 200) {
      throw new Error("Could not Upload PDF!");
    }
    const response = await makeRequest(
      "updateMemoRecipients",
      "PUT",
      sendRecipientsData,
      memoPDFUrl
    );

    if (!response) {
      throw response;
    }

    return { success: true, data: response };
  } catch (error) {
    console.error(error);
    return { success: false, data: error };
  }
};

interface IListMemosResponse {
  memos: IUserMemo[];
  lastEvaluatedKey?: any;
}

const getLastEvaluatedKeyString = (lastEvaluatedKey: any) => {
  const { customerId, memoId, recipientId } = lastEvaluatedKey;

  return `?customerId=${customerId}&memoId=${memoId}&recipientId=${recipientId}`;
};

export const getMemos = async () => {
  try {
    const memos: IUserMemo[] = [];
    let lastEvaluatedKey = undefined;
    do {
      const queryStringParameters = lastEvaluatedKey
        ? getLastEvaluatedKeyString(lastEvaluatedKey)
        : "";
      const response: IListMemosResponse = await makeRequest(
        `memos${queryStringParameters}`,
        "GET"
      );
      memos.push(...response.memos);
      lastEvaluatedKey = response.lastEvaluatedKey;
    } while (lastEvaluatedKey);

    return memos;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getMemo = async (memoId: string) => {
  const response: IUserMemo = await makeRequest(`memos/${memoId}`, "GET");
  return response;
};

export const getSignedMemoUrl = async (memoId: string) => {
  try {
    const memo = await getMemo(memoId);
    const response = await makeRequest(
      `memos/${memoId}/getSignedMemoUrl`,
      "GET",
      null,
      memoPDFUrl
    );
    return { memo, memoUrl: response.uploadURL };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const acknowledgeMemo = async (memoId: string) => {
  try {
    const response: IUserMemo = await makeRequest(
      `memos/${memoId}/acknowledge`,
      "PUT"
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const acknowledgeMemoOnBehalf = async (
  memoId: string,
  userId: string
) => {
  try {
    const response: IUserMemo = await makeRequest(
      `memos/${memoId}/acknowledgeOnBehalf/${userId}`,
      "PUT"
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addUserToMemo = async (
  memoId: string,
  users: IMemoEmailRecipient[]
) => {
  try {
    const response: IUserMemo = await makeRequest(
      `memos/${memoId}/addUserToMemo`,
      "PUT",
      { users }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const acknowledgeMemoFromToken = async (
  memoId: string,
  memoJWT: string
) => {
  try {
    const response: IUserMemo = await makeTokenRequest(
      `memos/${memoId}/acknowledge`,
      "PUT",
      memoJWT
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addUserToMemos = async (
  user: IMemoEmailRecipient,
  groupId: string,
  startDate: Date
) => {
  try {
    const response: IUserMemo = await makeRequest(
      `memos/addUserToGroupMemos`,
      "PUT",
      { user, groupId, startDate: startDate.getTime() }
    );
    return { success: true, data: response };
    // return response;
  } catch (error) {
    console.error(error);
    return { success: false, data: error };
  }
};
