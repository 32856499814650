import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Input, Label } from "reactstrap";
import { MicrosoftUsersSelect } from "../FormComponents/MicrosoftUsersSelect";
import { IMemoEmailRecipient } from "../../Memo";
import { LoadingSpinner } from "../Loading/LoadingSpinner";
import {
  setUsersRoles,
  getUserRoles,
} from "../../services/Admin/assignPermissions";
// import { setUser } from "../../redux/actions/UserActions";

import {UserList } from "./UserList"

interface IProps {}

export const AssignPermissions = (props: IProps) => {
  const [users, setUsers] = useState<IMemoEmailRecipient[]>([]);
  const [tableSelectedUsers, setTableSelectedUsers] = useState<IMemoEmailRecipient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cSelected, setCSelected] = useState<string[]>(["recipient"]);

  useEffect(() => {
    const getRoles = async () => {
      if (users.length === 1) {

        setIsLoading(true);
        const roles = await getUserRoles(users[0].userId, users[0].email);
        console.log("ROLES", roles)
        if (roles) {
          setCSelected(roles);
        }
        setIsLoading(false);
      } else {
        setCSelected(["recipient"]);
      }
    };
    getRoles();
    return () => {};
  }, [users]);

  const onCheckboxBtnClick = (selected: string) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };

  const submit = async () => {
    console.log(cSelected);
    console.log(users);
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await setUsersRoles(users, cSelected);
    setIsLoading(false);
  };

  const getAssignButton = () => {
    const buttonContent = isLoading ? <LoadingSpinner /> : "Submit";
    return (
      <Container>
        <Row>
          <Button
            style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            disabled={users.length <= 0 || isLoading}
            onClick={submit}
          >
            {buttonContent}
          </Button>
        </Row>
        <Row>
          <Col>
            {users.length <= 0 ? (
              <p style={{ marginLeft: "1rem", color: "red" }}>
                <i>select a user to assign roles</i>
              </p>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Container>
      <Row style={{ margin: "1rem" }}>
        <h2>Assign Permissions</h2>
      </Row>
      <Row>
        <Col>
          <MicrosoftUsersSelect
            multi={true}
            showGroups={false}
            setMemoRecipients={setUsers}
            defaultOptions={tableSelectedUsers}
          />
        </Col>
      </Row>

      <Container style={{ marginLeft: "1rem", marginTop: "1rem" }}>
        {/* <Row>
          <Col xs="2">
            <Label check>
              <Input
                type="checkbox"
                color="primary"
                onChange={() => onCheckboxBtnClick("recipient")}
                checked={cSelected.includes("recipient")}
                disabled={true}
              />
              <h4>Recipient</h4>
            </Label>
          </Col>
          <Col>
            <p>
              A recipient can only receive memos created by a sender and
              acknowledge them via unique link or by signing in
            </p>
          </Col>
        </Row> */}
        <Row>
          <Col xs="2">
            <Label check>
              <Input
                type="checkbox"
                color="primary"
                onChange={() => onCheckboxBtnClick("sender")}
                checked={cSelected.includes("sender")}
              />
              <h4>Sender</h4>
            </Label>
          </Col>
          <Col>
            <p>
              A sender can receive and create memos as well as view their
              created memos
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <Label check>
              <Input
                type="checkbox"
                color="primary"
                onChange={() => onCheckboxBtnClick("admin")}
                checked={cSelected.includes("admin")}
              />
              <h4>Admin</h4>
            </Label>
          </Col>
          <Col>
            <p>
              An admin can do all the above as well as perform admin tasks such
              as changing permissions for users, view all memos by customer, etc
            </p>
          </Col>
        </Row>
        {getAssignButton()}
      </Container>
      <UserList setSelectedUsers={setTableSelectedUsers} />
    </Container>
  );
};
