import React, { useState, useEffect, useRef } from "react";
import AsyncSelect from "react-select/async";
import {
  IOption,
  listMembersOfGroup,
  listFilteredGroups,
  listFilteredUsers,
} from "../../services/GraphService";
import { getAccessToken } from "../../services/UserAgent";
import { IMemoEmailRecipient } from "../../Memo";

const isEmailRegex = /[^@]+@[^.]+\..+/g;

interface IMemoGroupMap {
  [key: string]: IOption[];
}

interface IProps {
  setMemoRecipients?: (val: IMemoEmailRecipient[]) => void;
  setGroups?: (val: string[]) => void;
  showGroups: boolean;
  multi: boolean;
  defaultOptions?: IMemoEmailRecipient[];
}

let inputDelay: number | null = null;

export const MicrosoftUsersSelect = (props: IProps) => {
  const memoGroupMap = useRef<IMemoGroupMap>({});

  const { setMemoRecipients, setGroups, showGroups, multi, defaultOptions } = props;

  const [selectedOptions, setSelectedOptions] = useState<IOption[] | null>(
    null
  );
  useEffect(() => {
    if(!defaultOptions) {
      return;
    }
    const options = defaultOptions;
    setSelectedOptions(options.map((value: IMemoEmailRecipient) => {
      return {
        label: value.email,
        value: value.userId
      }
    }));
  }, [defaultOptions]);

  useEffect(() => {
    const getMemoRecipients = async () => {
      const accessToken = await getAccessToken();
      if (!selectedOptions || !accessToken) {
        if (setMemoRecipients) {
          setMemoRecipients([]);
        }
        return;
      }

      const recipientSet = new Set<IMemoEmailRecipient>();
      const groupSet = new Set<string>();
      const groupsLoaded = memoGroupMap.current;

      if (multi) {
        for (let option of selectedOptions) {
          if (option.label.match(isEmailRegex)) {
            recipientSet.add({ userId: option.value, email: option.label });
          } else {
            const groupMembers = groupsLoaded[option.value];
            if (!groupMembers) {
              const members = await listMembersOfGroup(
                option.value,
                accessToken
              );
              groupsLoaded[option.value] = members;
            }
            groupsLoaded[option.value].forEach((memberOption: IOption) =>
              recipientSet.add({
                userId: memberOption.value,
                email: memberOption.label,
              })
            );
            groupSet.add(option.value);
          }
        }
      } else {
        const option: IOption = (selectedOptions as unknown) as IOption;
        if (option.label.match(isEmailRegex)) {
          recipientSet.add({ userId: option.value, email: option.label });
        } else {
          const groupMembers = groupsLoaded[option.value];
          if (!groupMembers) {
            const members = await listMembersOfGroup(option.value, accessToken);
            groupsLoaded[option.value] = members;
          }
          groupsLoaded[option.value].forEach((memberOption: IOption) =>
            recipientSet.add({
              userId: memberOption.value,
              email: memberOption.label,
            })
          );
          groupSet.add(option.value);
        }
      }

      const memoRecipients = Array.from(recipientSet).reduce(
        (unique: IMemoEmailRecipient[], item: IMemoEmailRecipient) => {
          return unique.findIndex(
            (value: IMemoEmailRecipient) => value.userId === item.userId
          ) >= 0
            ? unique
            : [...unique, item];
        },
        []
      );
      if (setMemoRecipients) {
        setMemoRecipients(memoRecipients);
      }
      if (setGroups) {
        setGroups(Array.from(groupSet));
      }
    };

    getMemoRecipients();
  }, [selectedOptions, setMemoRecipients, setGroups, multi]);

  const handleChange = (selectedOption: any) => {
    console.log("SELECTED" , selectedOption)
    setSelectedOptions(selectedOption);
  };

  const getUsersAndGroups = async (inputValue: any) => {
    if (inputDelay) {
      clearTimeout(inputDelay);
      inputDelay = null;
    }
    return new Promise(resolve => {
      // @ts-ignore
      inputDelay = setTimeout(async () => {
        const accessToken = await getAccessToken();
        if (!accessToken) {
          return [];
        }
        const groups = showGroups
          ? await listFilteredGroups(accessToken, inputValue)
          : [];
        const users = await listFilteredUsers(accessToken, inputValue);

        resolve([...groups, ...users]);
      }, 200);
    });
  };
  return (
    <div style={{ color: "black" }}>
      <AsyncSelect
        // defaultOptions={defaultOptions ?? []}
        cacheOptions
        loadOptions={getUsersAndGroups}
        value={selectedOptions}
        onChange={handleChange}
        // options={options}
        isMulti={multi}
      />
    </div>
  );
};
