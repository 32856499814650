import React, { useCallback, useState } from "react";
import { getCustomerMemosAudit } from "../../services/Audit/audit";
import { Button } from "reactstrap";
import { LoadingSpinner } from "../Loading/LoadingSpinner";

interface IProps { }

export const CustomerMemosAudit = (props: IProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const getCustomerMemosAuditAction = useCallback(async () => {
        setIsLoading(true);
        const memosPDF: any = await getCustomerMemosAudit();
        console.log("response");
        let pdfWindow = window.open("")
        pdfWindow?.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(memosPDF.pdf) + "'></iframe>"
        )
        console.log(memosPDF);
        setIsLoading(false);
    }, []);

    if(isLoading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Button onClick={getCustomerMemosAuditAction}>Generate Audit</Button>
        </div>
    )
}