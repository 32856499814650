import { makeRequest } from "../api";

export interface IMemoRecipient {
  email: string;
  recipientId: string;
  emailSent?: boolean;
  userAcknowledged?: {
    acknowledgedAt: number;
  };
}

export const listMemoRecipients = async (memoId: string) => {
  try {
    const response: IMemoRecipient[] = await makeRequest(
      `memos/${memoId}/listRecipients`,
      "GET"
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
