import React, { useEffect, useState, useCallback } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { CreatedMemoDetails } from "../../components/CreatedMemoDetails";
import { useParams } from "react-router";
import { IMemoProps } from "../../components/Memo";
import { getSignedMemoUrl } from "../../services/Memo";
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner";
import moment from "moment";
import { getMemoAudit } from "../../services/Audit/audit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileAlt,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";

interface IProps {}

//Terrible Name...let's change it to something else.
interface IMemoId {
  id: string;
}

export const CreatedMemo = (props: IProps) => {
  const { id } = useParams<IMemoId>();
  const [uniqueMemo, setUniqueMemo] = useState<IMemoProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMemoUrl = async () => {
      if (id) {
        setIsLoading(true);
        const retrievedMemo = await getSignedMemoUrl(id);
        setUniqueMemo(retrievedMemo);
        setIsLoading(false);
      }
    };
    fetchMemoUrl();
    return () => {};
  }, [id]);

  const viewMemo = () => {
    window.open(memoUrl, "_blank");
  };

  const exportToPDF = useCallback(async () => {
    // setIsLoading(true);
    const response: any = await getMemoAudit(id);
    console.log("response");
    let pdfWindow = window.open("");
    pdfWindow?.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(response.pdf) +
        "'></iframe>"
    );
    // setIsLoading(false);
  }, [id]);

  const downloadToPdf = useCallback(async () => {
    //setIsLoading(true);
    const response: any = await getMemoAudit(id);
    const linkSource = `data:application/pdf;base64,${encodeURI(response.pdf)}`;
    const downloadLink = document.createElement("a");
    const fileName = `${uniqueMemo?.memo.subject ?? "audit"}_audit.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    //setIsLoading(false);
  }, [id, uniqueMemo]);

  if (!id) {
    return <h1>invalid memo!</h1>;
  }
  if (!uniqueMemo || isLoading) {
    return <LoadingSpinner />;
  }

  const { memo, memoUrl } = uniqueMemo;
  console.log(memo);
  return (
    <Container style={{ textAlign: "center" }}>
      <Row>
        <Col>
          <h1>Memo: {memo.subject}</h1>
        </Col>
      </Row>
      <Row>
        {memo.body && (
          <Col>
            <b>Body: </b>
            {memo.body}
          </Col>
        )}
        {memo.classification && (
          <Col>
            <b>Classification: </b>
            {memo.classification}
          </Col>
        )}
        {memo.createdAt && (
          <Col>
            <b>Created At: </b>
            {moment(memo.createdAt).format("LLL")}
          </Col>
        )}
      </Row>
      {/* <Row>
        {memo.createdBy && (
          <Col>
            <b>Created By: </b>
            {memo.createdByEmail}
          </Col>
        )}
      </Row> */}

      <Row style={{ margin: "1rem", textAlign: "left" }}>
        <Col>
          <h2>Recipients Status</h2>
        </Col>
        {/* <Col>
        </Col> */}
        <Col sm={6}>
          <Button
            className="my-2 mx-2"
            style={{ borderRadius: "10px" }}
            onClick={exportToPDF}
          >
            <FontAwesomeIcon className="mx-1" icon={faFileSignature} />
            View Audit
          </Button>
          <Button
            className="my-2 mx-2"
            style={{ borderRadius: "10px" }}
            onClick={downloadToPdf}
          >
            <FontAwesomeIcon className="mx-1" icon={faFilePdf} />
            Download Audit
          </Button>
          <Button
            className="my-2 mx-2"
            style={{ borderRadius: "10px" }}
            onClick={viewMemo}
          >
            <FontAwesomeIcon className="mx-1" icon={faFileAlt} />
            View Memo
          </Button>
        </Col>
      </Row>
      <CreatedMemoDetails memoId={id} />
    </Container>
  );
};
