import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers";
import { Button, Jumbotron, Col, Row, Container } from "reactstrap";

interface IProps {
  authButtonMethod: () => Promise<void>;
}

export const WelcomeContent = (props: IProps) => {
  const roles = useSelector((state: IRootState) => state.user.roles);
  const user = useSelector((state: IRootState) => state.user.user);
  // If authenticated, greet the user
  if (user && roles.length > 0) {
    return null;
  } else if (user && roles.length === 0) {
    return (
      <Jumbotron>
        <div>
          <h1>Sign up soon™ For Colert!</h1>
          <p>
            We have logged in through office 365 but you are not a member of
            Colert!
          </p>
        </div>
      </Jumbotron>
    );
  }

  // Not authenticated, present a sign in button
  return (
    <Container>
      <Jumbotron>
        <Row>
          <Col>
            <Button color="primary" onClick={props.authButtonMethod}>
              Click here to sign in
            </Button>
          </Col>
        </Row>
      </Jumbotron>
    </Container>
  );
};
