import React from "react";
import { HashRouter as Router } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
import "./superhero-bootstrap.css";
// import "flatly-bootstrap.css";
import "./App.css";
import { Provider } from "react-redux";

import store from "./redux/store";

import { Colert } from "./pages/Colert";

interface IProps {}

const App = (/*props: IProps*/) => {
  return (
    <Provider store={store}>
      <Router>
        <Colert />
      </Router>
    </Provider>
  );
};

export default App;
