import { combineReducers } from "redux";

import {MemoReducer, IMemoState} from './MemoReducer'
import { IUserState, UserReducer } from "./UserReducer";

export interface IRootState {
  memos: IMemoState;
  user: IUserState;
}

export default combineReducers({ 
  memos: MemoReducer,
  user: UserReducer
});
