import React, { useEffect, useState, useMemo } from "react";
import { Container } from "reactstrap";
import { getUsers } from "../../services/GraphService";
import { IMemoEmailRecipient } from "../../Memo";
import ReactTable from "react-table";

interface IProps {
  setSelectedUsers: (val: IMemoEmailRecipient[]) => void;
}
// @ts-ignore
const filterCaseInsensitive = ({ id, value }, row: any) =>
  row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

export const UserList = (props: IProps) => {
  const { setSelectedUsers } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    const getUsersAction = async () => {
      setIsLoading(true);
      const users = await getUsers();
      // console.log(users)
      setUsers(users);
      setIsLoading(false);
    };
    getUsersAction();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Email",
        accessor: "mail",
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },
    ],
    []
  );

  return (
    <Container style={{ marginTop: "2rem" }}>
      <h2>User List</h2>
      <ReactTable
        className={"-highlight -striped"}
        loading={isLoading}
        data={users}
        columns={columns}
        filterable
        style={{ maxHeight: "100%" }}
        // defaultPageSize={10}
        defaultFilterMethod={filterCaseInsensitive}
        defaultSorted={[{ id: "displayName", desc: false }]}
        getTrProps={(state: any, rowInfo: any) => {
          return {
            onClick: (event: any) => {
              event.persist();
              setSelectedUsers([
                { userId: rowInfo.original.id, email: rowInfo.original.mail },
              ]);
              console.log(rowInfo);
            },
          };
        }}
      />
    </Container>
  );
};
