import { getAccessToken } from "./UserAgent";
import axios from "axios";
import { logout } from "./UserService";
import jwtDecode from "jwt-decode";
//'http://localhost:3001/'

// HACK:
export let apiURL: string;
export let isLocal: boolean;

// if (/https?:\/\/app\.colert.*/gi.test(window.location.hostname)) {
//   apiURL = "https://h4mtqebay8.execute-api.ca-central-1.amazonaws.com/prod/";
//   isLocal = false;
// } else {
//   apiURL = "https://1ioepkyr72.execute-api.ca-central-1.amazonaws.com/dev/";
//   isLocal = true;
// }
// End HACK

apiURL = "https://h4mtqebay8.execute-api.ca-central-1.amazonaws.com/prod/";
isLocal = false;

// export const isLocal = ["localhost", "127.0.0.1", ""].includes(
//   window.location.hostname
// );
// export const apiURL = isLocal
//   ? "https://1ioepkyr72.execute-api.ca-central-1.amazonaws.com/dev/"
//   : "https://h4mtqebay8.execute-api.ca-central-1.amazonaws.com/prod/";

export type RestMethod = "GET" | "POST" | "PUT" | "DELETE";

const isTokenExpired = async (apiToken: string) => {
  if (!apiToken) {
    return true;
  }
  try {
    const decoded: any = await jwtDecode(apiToken);
    const expire = decoded.exp;
    if (!expire) {
      return true;
    }
    return expire * 1000 <= Date.now();
  } catch (error) {
    console.error(error);
  }
  return false;
};

const setNewApiToken = async () => {
  console.log("Fetching new API token!");
  const microsoftAccessToken = await getAccessToken();
  if (!microsoftAccessToken || !microsoftAccessToken.accessToken) {
    console.log("NO microsoft access token!");
    logout();
    localStorage.setItem("colert-token", "");
    return null;
  }
  try {
    const response = await axios(apiURL + "auth/fetchNewToken", {
      headers: {
        "Content-Type": "application/json",
        Authorization: microsoftAccessToken.accessToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    localStorage.setItem("colert-token", response.data.token);

    return response.data.token;
  } catch (error) {
    console.error(error);
    localStorage.setItem("colert-token", "");
    return null;
  }
};

export const getApiToken = async () => {
  const apiToken = localStorage.getItem("colert-token");
  if (!apiToken || (await isTokenExpired(apiToken))) {
    return await setNewApiToken();
  }

  return apiToken;
};

export const getRoles = async () => {
  const apiToken = await getApiToken();
  try {
    const decoded: any = await jwtDecode(apiToken);
    return decoded.roles;
  } catch (error) {
    return [];
  }
};

export const getCustomerId = async () => {
  const apiToken = await getApiToken();
  try {
    const decoded: any = await jwtDecode(apiToken);
    return decoded.customerId;
  } catch (error) {
    return [];
  }
};

export interface IResponse {
  success: boolean;
  data: any;
}

/**
 * makeRequest helper function that makes a fetch request with the right headers
 * @param route the route or path to the request
 * @param method REST method
 * @param data any data to send
 */
export const makeRequest = async (
  route: string,
  method: RestMethod,
  data: any = null,
  baseAPI: string | null = null
) => {
  try {
    const apiToken = await getApiToken();
    // Default options are marked with *
    const url = (baseAPI ? baseAPI : apiURL) + route;
    const response = await axios(url, {
      method, // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: apiToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrer: 'no-referrer', // no-referrer, *client
      data: method !== "GET" ? JSON.stringify(data) : null // body data type must match "Content-Type" header
    });
    return response.data; // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};

export const makeUnauthorizedRequest = async (
  route: string,
  method: RestMethod,
  data: any = null
) => {
  // Default options are marked with *
  const url = apiURL + route;
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: method !== "GET" ? JSON.stringify(data) : null // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
};

export const makeTokenRequest = async (
  route: string,
  method: RestMethod,
  token: string,
  data: any = null
) => {
  const url = apiURL + route;
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: method !== "GET" ? JSON.stringify(data) : null // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
};
