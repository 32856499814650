import { Type, Action } from "../actions/MemoActions";
import { IMemoMap } from "../../../../shared/Memo";

// export interface INewMemo {
//   userId: string;
//   data: any;
//   recipients: string[]
// }
// export const isNewMemo = (object: any): object is INewMemo => {
//   return 'userId' in object && 'data' in object && 'recipients' in object
// }

export interface IMemo {
  data: any;
  memoId: string;
  createdAt: number;
  updatedAt: number;
  usersAcknowledged: string[];
  createdBy: string;
}
export const isMemo = (object: any): object is IMemo => {
  return (
    "memoId" in object &&
    "createdAt" in object &&
    "updatedAt" in object &&
    "isAcknowledged" in object
  );
};
// export interface IMemo {
//   createdAt: number;
//   data: any
//   memoId: string;
//   recipients: string[]
//   updatedAt: number;
//   userId: string;
//   usersAcknowledged: string[]
// }

export interface IMemoState {
  memos: IMemoMap;
}

const defaultState: IMemoState = {
  memos: {},
};

export const MemoReducer = (
  state: IMemoState = defaultState,
  action: Action
): IMemoState => {
  switch (action.type) {
    case Type.ADD_MEMO:
      return { ...state, memos: { ...state.memos, ...action.memo } };
    case Type.GET_MEMOS:
      return { ...state, memos: { ...action.memos } };
    default:
      return state;
  }
};
