import React, { useEffect, useState } from "react";
import { Fade } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../redux/reducers";
import NavBar from "../navigation/NavBar";
import {
  login,
  isUserError,
  logout,
  getUserProfile
} from "../services/UserService";
import { setUser, setUserRoles } from "../redux/actions/UserActions";
import { Route, Switch } from "react-router";
import Welcome from "./Welcome";
import CreateMemo from "./CreateMemo";
import { getUserAgentApplication } from "../services/UserAgent";
import { AuthMemo } from "./AuthMemo";
import { UniqueMemo } from "./UniqueMemo";
import { getRoles } from "../services/api";
import { hasRecipientRole, hasAdminRole } from "../redux/reducers/UserReducer";
import { CreatedMemos } from "./AdminMemos/CreatedMemos";
import { LoadingSpinner } from "../components/Loading/LoadingSpinner";
import { AddUserToMemos } from "./AdminMemos/AddUserToMemos";
import { AdminPage } from "./AdminPage";
import { CreatedMemo } from "./AdminMemos/CreatedMemo";
import { getTermsAndConditions } from "../services/Admin/termsAndConditions";
import { ShowTermsAndConditions } from "../components/admin/ShowTermsAndConditions";

interface IProps {}

export const Colert = (props: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const user = useSelector((state: IRootState) => state.user.user);
  const roles = useSelector((state: IRootState) => state.user.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const userAgentApplication = getUserAgentApplication();
      const user = userAgentApplication.getAccount();
      if (user) {
        // Get user Profile from graph api to display to user
        const response = await getUserProfile();
        if (!isUserError(response)) {
          dispatch(setUser(response));
        }
      }
      setIsLoading(false);
    };
    getUser();
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    const getRolesEffect = async () => {
      setIsLoading(true);
      //Get user roles from Colert API
      const roles = await getRoles();
      dispatch(setUserRoles(roles));
      const termsAndConditionsResponse = await getTermsAndConditions(
        user && user.id ? user.id : ""
      );
      setTermsAndConditions(termsAndConditionsResponse);
      setIsLoading(false);
    };

    if (user) {
      getRolesEffect();
    }
  }, [dispatch, user]);

  const handleLogin = async () => {
    const response = await login();
    if (!isUserError(response)) {
      dispatch(setUser(response));
    }
  };

  const handleLogout = async () => {
    logout();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (termsAndConditions) {
    return (
      <div className="maxHeight" style={{ fontFamily: "Montserrat" }}>
        <NavBar
          authButtonMethod={roles.length > 0 ? handleLogout : handleLogin}
        />
        <ShowTermsAndConditions userId={user ? user.id : ""} />
      </div>
    );
  }

  return (
    <div className="maxHeight" style={{ fontFamily: "Montserrat" }}>
      <NavBar
        authButtonMethod={roles.length > 0 ? handleLogout : handleLogin}
      />
      <div className="maxHeight">
        <Fade className="maxHeight">
          <Switch>
            <Route
              path="/memos/:id"
              render={() =>
                hasRecipientRole(roles) ? (
                  <AuthMemo showAcknowledge={true} />
                ) : null
              }
            />
            {/* <Route
              exact
              path="/calendar"
              render={() => (hasRecipientRole(roles) ? <Calendar /> : null)}
            /> */}
            <Route
              exact
              path="/createMemo"
              render={() => (hasAdminRole(roles) ? <CreateMemo /> : null)}
            />
            <Route
              exact
              path="/createdMemos"
              render={() => (hasAdminRole(roles) ? <CreatedMemos /> : null)}
            />
            <Route
              path="/createdMemos/:id"
              render={() =>
                hasAdminRole(roles) ? (
                  <CreatedMemo />
                ) : // <AuthMemo showAcknowledge={false} />
                null
              }
            />
            <Route
              exact
              path="/addUserToMemos"
              render={() => (hasAdminRole(roles) ? <AddUserToMemos /> : null)}
            />
            <Route path="/uniqueMemo/:memoJWT" render={() => <UniqueMemo />} />
            <Route path="/admin" render={() => <AdminPage />} />
            <Route render={() => <Welcome authButtonMethod={handleLogin} />} />
          </Switch>
        </Fade>
      </div>
    </div>
  );
};
