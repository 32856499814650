import { IUserMemo, IMemo } from "../Memo";

import { makeRequest } from "./api";

interface ICreatedMemosResponse {
  memos: IMemo[];
  lastEvaluatedKey?: any;
}
const getLastEvaluatedKeyString = (lastEvaluatedKey: any) => {
  const { customerId, memoId, recipientId } = lastEvaluatedKey;

  return `?customerId=${customerId}&memoId=${memoId}&recipientId=${recipientId}`;
};

export const getCreatedMemos = async () => {
  try {
    const memos: IMemo[] = [];
    let lastEvaluatedKey = undefined;
    do {
      const queryStringParameters = lastEvaluatedKey
        ? getLastEvaluatedKeyString(lastEvaluatedKey)
        : "";
      const response: ICreatedMemosResponse = await makeRequest(
        `createdMemos${queryStringParameters}`,
        "GET"
      );
      memos.push(...response.memos);
      lastEvaluatedKey = response.lastEvaluatedKey;
    } while (lastEvaluatedKey);
    return memos;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCreatedMemo = async (memoId: string) => {
  const response: IUserMemo = await makeRequest(`memos/${memoId}`, "GET");
  return response;
};
