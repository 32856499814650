import React, { useEffect, useState } from "react";
import { acknowledgeMemo, getSignedMemoUrl } from "../services/Memo";
import { useParams } from "react-router";
import { Memo, IMemoProps } from "../components/Memo";
import { LoadingSpinner } from "../components/Loading/LoadingSpinner";

interface IProps {
  showAcknowledge: boolean;
}
/**
 * AuthMemo React Component that retrieves a memo based on a unique JWT sent to
 * an unauthorized user via email
 * @param props structure of the props passed into AuthMemo
 */
export const AuthMemo = (props: IProps) => {
  const { id } = useParams();
  const [uniqueMemo, setUniqueMemo] = useState<IMemoProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMemoUrl = async () => {
      if (id) {
        setIsLoading(true);
        const retrievedMemo = await getSignedMemoUrl(id);
        setUniqueMemo(retrievedMemo);
        setIsLoading(false);
      }
    };
    fetchMemoUrl();
    return () => {};
  }, [id]);

  const acknowledgeMemoAction = async () => {
    setIsLoading(true);
    const ackMemo = await acknowledgeMemo(memo.memoId);
    if (ackMemo) {
      setUniqueMemo({
        memoUrl: uniqueMemo ? uniqueMemo.memoUrl : "",
        memo: ackMemo,
      });
    }
    setIsLoading(false);
  };

  if (!uniqueMemo || isLoading) {
    return <LoadingSpinner />;
  }

  const { memo, memoUrl } = uniqueMemo;

  return (
    <Memo
      memo={memo}
      memoUrl={memoUrl}
      isLoading={isLoading}
      showAcknowledge={props.showAcknowledge}
      acknowledgeMemo={acknowledgeMemoAction}
    />
  );
};
