import React from "react";
import { Container, Row, Col, Spinner } from "reactstrap";

export const LoadingSpinner = () => {
  return (
    <Container>
      <Row>
        <Col xs={{ size: 1, offset: 5 }}>
          <Spinner className="my-5" style={{ width: "3rem", height: "3rem" }}>
            Loading
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};
